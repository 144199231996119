@font-face {
  font-family: 'Myriadpro-regular';
  src: url('./assets/myriad-pro-fonts/MYRIADPRO-REGULAR.OTF') format('truetype');
}
@font-face {
  font-family: 'Myriadpro-light';
  src: url('./assets/myriad-pro-fonts/MyriadPro-Light.otf') format('truetype');
}

@font-face {
  font-family: 'Myriadpro-bold';
  src: url('./assets/myriad-pro-fonts/MYRIADPRO-BOLD.OTF') format('truetype');
}
@font-face {
  font-family: 'Myriadpro-semibold';
  src: url('./assets/myriad-pro-fonts/MYRIADPRO-SEMIBOLD.OTF') format('truetype');
}

body, html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  font-family:'Myriadpro-regular';
}
