.container {
    background: transparent;
    align-items: center;
    height: 100%;
    margin-top: 10vh;
  }
  
  
  .log-container {
    margin: 0 auto;
    top: 30%;
    background: #f4f4f4;
    width: 400px;
    height: 400px;
    padding:50px;
    left:0;
    right:0;
    border-radius: 2px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    align-items:center;
    position: absolute;
    box-sizing: border-box;
    box-shadow: 3px 5px 5px rgba(49, 49, 48, 0.1);
  }
  
  .log-container--invalid {
    margin: 0 auto;
    left:0;
    right:0;
    top: 30%;
    background: #f4f4f4;
    width: 400px;
    height: 200px;
    position: absolute;
    display: flex;
    box-sizing: border-box;
    align-items:center;
    box-shadow: 3px 5px 5px rgba(49, 49, 48, 0.1);
  }

  .log-container--invalid > p {
    margin: 0 auto;
  }

  .log-container_shaking {

    animation: shake 0.2s ease-out;
  }

  @keyframes shake {
    /* declare animation actions here */
    20% {
      transform: translateX(-10px);
    }
    60% {
      transform: translateX(20px);
    }
    100% {
      transform: translateX(-10px);
    }
  }
  
  
  .log-container_title {
    font-size: 20pt;
    font-family: "Myriadpro-light";
    color: #403d38;
    margin-bottom: 10px;
  }
  
  .log-container_underline {
    width: 40px;
    border-top: 1px solid #ff5200;
  }
  
  .log-container_instructions {
      font-size: 10.5pt;
      font-family: 'Myriadpro-semibold';
      color: #403d38;
  }


  .MuiInputBase-root {
    font-family: "Myriadpro-regular" !important;
  }
  
  .MuiFormLabel-root {
    font-family: "Myriadpro-regular" !important;
  }
  
  .MuiInput-underline:after {
    border-bottom: 2px solid #ff5200 !important;
    bottom: -10px !important;
  }
  
  .MuiInput-underline:before {
    border-bottom: 1px solid #61676b !important;
    bottom: -10px !important;
  
  }
  
  .MuiFormLabel-root.Mui-focused {
    color: #ff5200 !important;
  }
  
  .MuiInputLabel-formControl {
    left: 5px !important;
  }
  
  .MuiInputBase-input {
    padding-left: 5px !important;
  }
  
  .MuiFormLabel-root {
    color: #7c848f !important;
  }

  .MuiFormHelperText-root.Mui-error {
    color: red !important;
  }

  .MuiFormLabel-root.Mui-error {
    color: red !important;
  }
  
  .MuiFormHelperText-root {
    margin-left: 6px !important;
    margin-top: 10px !important;
    font-family: "Myriadpro-regular" !important;
  }

  .log-container_link {
    color: #7c848f;
    text-decoration: underline;
    align-self: flex-start;
    margin-left: -6px;
    margin-top: 14px;
    font-size: 11pt;
  }
  
  .log-container_link:hover {
    color: #656a72;
  }
  
  .form {
    width:100%;
    height: 170px;
    margin: 0 auto;
    margin-top: 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  .form-row {
    padding-bottom: 1rem;
  }
  
  .align-button {
    margin:0 auto;
    /* margin-top: 20px; */
    margin-left: -6px !important;
    width: 312px !important;
    min-width: 80px;
    font-size: 1rem !important;
    background-color: #ff5200 !important;
    border-radius: 0 !important;
    border:none !important;
  }
  
  .align-button:hover {
    background-color: #b44d1c !important;
  }
  
  .form-control:focus {
    border-color: rgba(54, 49, 46, 0.8) !important;
    box-shadow: 0 1px 1px rgba(49, 49, 48, 0.075) inset, 0 0 8px rgba(20, 19, 18, 0.6) !important;
    outline: 0 none !important;
  }
  
  
  .form-label {
    font-size: 0.8rem;
  }
  
  .form-control {
   font-size: 0.8rem !important;
  }
  
  .alert-window {
    box-sizing: border-box;
    width: 403px;
    position: absolute !important;
    left: -13px;
    right: 0;
    margin: auto;
    top: 0;
    opacity: 0;
    display: none !important;
  }

  .alert-window__error {
    border-left: 10px solid red;
  }
  
  .alert-window__success {
    border-left: 10px solid rgb(71, 184, 129);
  }

  .alert-window-show {
    box-sizing: border-box;
    width: 403px;
    position: absolute;
    left: 0px;
    right: 0;
    margin: auto;
    top: 13vh;
    animation: jumpin 0.4s ease-out;
  }

  @keyframes jumpin {
    /* declare animation actions here */
    0% {
      opacity: 0;
      display: block;
      top: 50px;
    }

    100% {
      opacity: 1;
    }
  }

  .alert-window > h4,.ub-fnt-fam_b77syt {
    font-family: "Myriadpro-regular" !important;
    padding-right: 30px;
    color: #403d38;
  }