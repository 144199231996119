.header {
    height: 60px;
    width: 100vw;
    color: white;
    border-bottom: 1px solid #eceef0;
}

.header-container {
    padding-left: 20%;
    padding-right: 20%;
}

.logo-wrapper {
    display: inline-block;
    padding-top: 15px;
}

.logo-wrapper > img {
    float: left;
    height: 30px;
    padding-right: 8px;
}

.logo-wrapper > p {
    display: inline-block;
    color: #7c848f;
    padding-top: 8px;
    font-size: 10pt;
    font-weight: bold;
}

nav {
    float:right;
    padding-top: 10px;
}

nav li {
    display: inline-block;
  }

nav ul {
    margin:0;
    padding:0;
}

nav a {
    font-family: "MYRIADPRO-REGULAR";
    color: #403d38;
    font-size: calc(10px + 0.3vw);
}

.nav-link {
    padding: 0.5rem 0.5rem !important;
}

nav a:hover,.active {
    color: #ff5200;
}

.user-avatar {
    width: 35px;
    height: 35px;
}

.avatar-wrapper {
    padding-left: 20px;
    display: inline-block;
}

.avatar-wrapper > p {
    padding-left: 5px;
    display: inline-block;
    font-family: "MYRIADPRO-REGULAR";
    color: #403d38;
    font-size: calc(10px + 0.3vw);
}

.logout-btn {
    text-decoration: underline;
}

.logout-btn:hover, .logout-btn:visited,.logout-btn:active, .logout-btn:link{
    text-decoration: underline !important;
}