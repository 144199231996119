.mandate-info {
    margin-left: 16px;
}

.mandate-info > h6 {
    display: inline-block;
    font-family: "MYRIADPRO-REGULAR";
    color: #403d38;
}

.mandate-avatar-icon--info {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.actions-mandanteninfo-text {
    padding-right: 0px !important;
}

.actions-mandanteninfo > span:first-child{
    display: inline-block;
    padding-right: 20px;
}

.actions-mandanteninfo > span:nth-child(2){
    display: inline-block;
    padding-right: 12px;
}

.phone-row {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.return-link {
    font-family: "MYRIADPRO-REGULAR";
    color: #403d38;
    font-size: calc(10px + 0.3vw);
    display: inline-block;
    padding-top: 10px;
    padding-left: 15px;
}

.return-link:hover {
    color: #ff5200;
}

.return-link:hover, .return-link:visited,.return-link:active, .return-link:link{
    text-decoration: underline !important;
}