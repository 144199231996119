.mandate-header-container {
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-mandate-icon-button {
    color: #ff5200;
    padding: 5px 5px 5px 5px;
}

.mandate-table-head {
    background-color: #F4F4F4;
}

.mandate-avatar-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.mandate-icon-button {
    color: #ff5200;
    padding: 10px 10px 10px 10px;
}

.mandate-name-column {
    display: flex;
}

.mandate-state-column {
    display: flex;
    width: 150px;
}

.mandate-edit-column {
    display: flex;
    width: 150px;
}