.wrapper {
    padding-top: 25pt;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 25pt;
}

.container {
    overflow-y: scroll;
}

.settings-container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.setting-text {
    margin-right: 50px;
    flex: 0 1 auto;
    min-width: 250px;
    margin-right: 50px;
    font-size: 14pt;
    font-family: "MYRIADPRO-REGULAR";
    color: #403d38 !important;
    vertical-align: middle;
}

.switch {
    vertical-align: middle;
}

.slider {
    /* display: inline; */
    vertical-align: middle;
}

.switch-text {
    font-family: "MYRIADPRO-REGULAR";
    color: #7c848f;
    padding-left: 17px;
    /* display: inline-block; */
    display: inline;
    vertical-align: middle;
    white-space: nowrap;
}

.setting-container {
    padding: 15pt 0pt 5pt 10pt;
}

.settings-button {
    margin-top: 20px !important;
    margin-left: -6px;
    width: 100% !important;
    min-width: 80px;
    font-size: 1rem !important;
    background-color: #ff5200 !important;
    border-radius: 0 !important;
    border:none !important;
    color: white !important;
  }
  
  .settings-button:hover {
    background-color: #b44d1c !important;
  }


.slider-wrapper {
    display: inline-block;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 350px;
    min-width: 300px;
}

#datetime-local{
    color: #7c848f !important;
}