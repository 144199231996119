.telefon-import-text {
  margin: 0 auto;
  display: inline-block;
  padding-bottom: 5px;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    box-sizing: border-box;
  }

.upload-btn {
  background-color: gray !important;
  margin-bottom: 30px !important;
  margin:0 auto;
  margin-left: -6px !important;
  width: 318px !important;
  min-width: 80px;
  font-size: 1rem !important;
  border-radius: 0 !important;
  border:none !important;
  overflow: hidden;
  white-space: nowrap;
  
}

.upload-btn:hover {
  background-color: rgb(77, 75, 75) !important;
  
}

.form-telefon-import {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}



.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  right:0;
  top: 0;
  opacity: 0;
}

.upload-btn-wrapper input[type="file"]:hover {
    cursor: pointer !important;
}

