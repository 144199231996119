.custom-alert {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #ff5200;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #fff;
    /* z-index: 11;
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%); */
}

.custom-alert > button {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;
}



.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

@keyframes react-confirm-alert-fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.react-confirm-alert-body-element {
    overflow: hidden;
}

.react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}